import {cva} from 'class-variance-authority';
import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

interface Props {
  label: string;
  path: string;
  activeIcon: string;
  inactiveIcon: string;
  onClick: () => void;
}

const button = cva('navbutton', {
  variants: {
    intent: {
      default: [
        'flex',
        'flex-row',
        'hover:bg-gray-300',
        'rounded-[10px]',
        'h-[48px]',
        'w-full',
        'items-center',
        'pl-[15px]',
      ],
    },
    status: {
      active: ['bg-[#FBE4EC]', 'text-smoothRed', 'font-semibold'],
      inactive: ['md:bg-OffWhite', 'text-black'],
    },
  },
  defaultVariants: {
    intent: 'default',
    status: 'inactive',
  },
});

const NavItem: React.FC<Props & {onClick: () => void}> = ({
  label,
  path,
  activeIcon,
  inactiveIcon,
  onClick,
}) => {
  const location = useLocation();
  const active = location.pathname.includes(path);
  const nav = useNavigate();

  return (
    <button
      onClick={() => {
        nav(path);
        onClick(); // Call the onClick prop to close the sidebar
      }}
      className={button({
        intent: 'default',
        status: active ? 'active' : 'inactive',
      })}>
      <img src={active ? activeIcon : inactiveIcon} />
      <p className="ml-[15px]">{label}</p>
    </button>
  );
};

export default NavItem;
