import {Input} from '@cashiaApp/web-components';
import {LoadScriptNext} from '@react-google-maps/api';
import React, {useState, useRef, useEffect} from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';

import {ReactComponent as LocationIcon} from '../../assets/icons/location_icon.svg';

const libraries: 'places'[] = ['places'];

interface Prediction {
  description: string;
  place_id: string;
  terms: {value: string}[];
}

type Props = {
  onSelect?: (x: string) => void;
  defaultValue?: string;
};

const CustomAutocomplete: React.FC<Props> = ({onSelect, defaultValue}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [predictions, setPredictions] = useState<Prediction[]>([]);
  const autocompleteServiceRef =
    useRef<google.maps.places.AutocompleteService | null>(null);
  const placesServiceRef = useRef<google.maps.places.PlacesService | null>(
    null
  );

  useEffect(() => {
    if (defaultValue) {
      setInputValue(defaultValue);
    } else {
      setInputValue('');
    }
  }, [defaultValue]);

  const ref = useOnclickOutside(() => {
    setPredictions([]);
  });

  const onLoad = () => {
    if (!autocompleteServiceRef.current && window.google) {
      autocompleteServiceRef.current =
        new google.maps.places.AutocompleteService();
    }
    if (!placesServiceRef.current && window.google) {
      const placesServiceDiv = document.createElement('div');
      placesServiceRef.current = new google.maps.places.PlacesService(
        placesServiceDiv
      );
    }
  };

  const handleInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setInputValue(value);
    if (!value) {
      onSelect?.('');
      return;
    }

    if (!autocompleteServiceRef.current) {
      if (window.google) {
        autocompleteServiceRef.current =
          new google.maps.places.AutocompleteService();
      }
    }

    if (value.length > 0 && autocompleteServiceRef.current) {
      await autocompleteServiceRef.current.getPlacePredictions(
        {
          input: value,
          componentRestrictions: {country: 'KE'},
        },
        (predictionsList) => {
          if (predictionsList) {
            setPredictions(predictionsList as Prediction[]);
          } else {
            setPredictions([]);
          }
        }
      );
    } else {
      setPredictions([]);
    }
  };

  const fetchPlaceDetails = (
    placeId: string
  ): Promise<google.maps.places.PlaceResult> =>
    new Promise((resolve, reject) => {
      if (placesServiceRef.current) {
        placesServiceRef.current.getDetails(
          {placeId},
          (placeDetails, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              resolve(placeDetails as google.maps.places.PlaceResult);
            } else {
              reject(new Error('Failed to fetch place details'));
            }
          }
        );
      } else {
        reject(new Error('PlacesService is not initialized'));
      }
    });

  const handleSelectPrediction = async (placeId: string) => {
    setPredictions([]);

    try {
      const placeDetails = await fetchPlaceDetails(placeId);
      setInputValue(
        `${placeDetails?.name || ''}, ${placeDetails?.vicinity || ''}`
      );
      onSelect?.(
        `${placeDetails?.name || ''}, ${placeDetails?.vicinity || ''}`
      );
    } catch (error) {
      console.error('Error fetching place details:', error);
    }
  };
  return (
    <LoadScriptNext
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}
      libraries={libraries}
      onLoad={onLoad}
      loadingElement={
        <div className="max-md:w-[88vw] w-[497px] bg-transparent border-dividerGrey h-[46px] focus:outline-none focus:ring-0 focus:border-red-300" />
      }>
      <div className="relative max-md:w-[88vw] w-[400px]">
        <Input
          className="max-md:w-[88vw] w-[497px] text-[14px] bg-transparent border-dividerGrey h-[46px] focus:outline-none focus:ring-0 focus:border-red-300"
          value={inputValue}
          name="location"
          onChange={handleInputChange}
        />
        <LocationIcon className="relative bottom-[30px] max-md:left-[82vw] left-[465px]" />
        {predictions.length > 0 && (
          <div
            ref={ref}
            className="shadow-lg cursor-pointer max-md:w-[88vw] w-[497px] h-[327px] bg-white absolute top-[100%] left-0 right-0 z-[1000] rounded-[10px]">
            <ul>
              {predictions.map((prediction) => (
                <li
                  key={prediction.place_id}
                  id="location-suggestion"
                  onClick={() => handleSelectPrediction(prediction.place_id)}
                  className="bg-white px-4 py-2 border-b-[1px] border-dividerGrey">
                  <strong className="font-[500]">
                    {prediction?.terms?.[0]?.value}
                  </strong>
                  <br />
                  <small className="font-[400] text-[12px] leading-[12px] text-foggy">
                    {prediction.description}
                  </small>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </LoadScriptNext>
  );
};

export default CustomAutocomplete;
