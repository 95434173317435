import {useState} from 'react';
import {Outlet} from 'react-router-dom';

import BurgerIcon from '../../assets/icons/burger.svg';
import CloseIcon from '../../assets/icons/close_outlined.svg';
import Sidebar from '../../components/common/Sidebar';
import Toolbar from '../../components/common/Toolbar';

const Layout = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => setIsOpen(!isOpen);

  // Function to close the sidebar
  const closeSidebar = () => setIsOpen(false);

  return (
    <div className="flex h-screen">
      <div
        className={`w-full md:w-72 md:bg-offWhite text-black border-r border-Greyish md:py-5 px-4  fixed pt-20 md:pt-5 inset-x-0 transform ${
          isOpen ? 'translate-y-0' : '-translate-y-full'
        }  duration-300 ease-in-out md:translate-y-0 h-full`}>
        <Sidebar onNavItemClick={closeSidebar} />
      </div>

      <div className="flex-1 w-full">
        <div
          className={`flex flex-1 justify-between transition-all duration-300 ease-in-out fixed top-0 left-0 right-0 h-20 py-5 px-4 md:px-7 border-b border-Greyish bg-white md:ml-72 ${
            isOpen ? 'border-b-0' : 'border-b border-Greyish'
          }`}>
          <div className="flex justify-center">
            <button onClick={toggleSidebar} className="md:hidden">
              <img src={isOpen ? CloseIcon : BurgerIcon} />
            </button>
          </div>
          {!isOpen && <Toolbar />}
        </div>

        <div
          className={`flex-1 px-4 md:px-[50px] pt-24 pb-[30px] transition-all duration-300 ease-in-out ${
            isOpen ? 'hidden md:ml-72' : 'ml-0'
          } md:ml-72`}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
