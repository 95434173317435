import {cva} from 'class-variance-authority';
import React from 'react';

import NavItem from './NavItem';
import HomeActive from '../../assets/icons/home_active.svg';
import HomeInactive from '../../assets/icons/home_inactive.svg';
import LinkActive from '../../assets/icons/link_active.svg';
import LinkInactive from '../../assets/icons/link_inactive.svg';
import LogoutIcon from '../../assets/icons/logout.svg';
import ShopIcon from '../../assets/icons/shop.svg';
import {useAuth} from '../../utils/auth';
import {useUserAuth} from '../../utils/user';

const icon = cva('navicon', {
  variants: {
    intent: {
      default: ['w-[24px]', 'h-[24px]', 'mr-[10px]'],
      header: ['w-[24px]', 'h-[24px]', 'mr-[10px]'],
    },
  },
  defaultVariants: {
    intent: 'default',
  },
});

interface SidebarProps {
  onNavItemClick: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({onNavItemClick}) => {
  const {user} = useUserAuth();
  const {logout} = useAuth();

  return (
    <div className="flex flex-1 h-full flex-col justify-between">
      <div>
        <div className="flex flex-row items-center">
          <img className={icon({intent: 'header'})} src={ShopIcon} />
          <p className="font-semibold text-[20px]">{`${user?.firstName || ''} ${
            user?.lastName || ''
          }`}</p>
        </div>
        <div className="mt-10 flex flex-col gap-[5px]">
          <NavItem
            label="Home"
            path="/home"
            activeIcon={HomeActive}
            inactiveIcon={HomeInactive}
            onClick={onNavItemClick} // Pass down the click handler
          />
          <NavItem
            label="Links"
            path="/links"
            activeIcon={LinkActive}
            inactiveIcon={LinkInactive}
            onClick={onNavItemClick} // Pass down the click handler
          />
        </div>
      </div>
      <button
        onClick={logout}
        className="flex flex-row hover:bg-gray-300 rounded-md h-[48px] items-center pl-[15px]">
        <img src={LogoutIcon} />
        <p className="text-[#EA3A3D] pl-[15px] font-semibold">Logout</p>
      </button>
    </div>
  );
};

export default Sidebar;
