import {Observable} from '@apollo/client';
import React, {
  useContext,
  useCallback,
  useState,
  PropsWithChildren,
  useEffect,
} from 'react';

import {
  localStorageRefreshTokenKey,
  localStorageAccessTokenKey,
} from './constants';

interface NewTokens {
  newAccessToken: string;
  newRefreshToken: string;
}

interface ExtendedAuth extends gapi.auth2.GoogleAuth {
  signOut(): Promise<void>;
  disconnect(): void;
}

interface Context {
  isAuthenticated: boolean;
  accessToken: string | null;
  refreshToken: string | null;
  logout(): Promise<void>;
  login: (accessToken: string, refreshToken: string) => Promise<void>;
  getNewTokens(): Promise<NewTokens | undefined>;
}

export const AuthContext = React.createContext<Context>({
  isAuthenticated: false,
  accessToken: null,
  refreshToken: null,
  logout: async () => undefined,
  login: async () => undefined,
  getNewTokens: async () => undefined,
});

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
export const promiseToObservable = (
  promise: Promise<NewTokens | undefined>
): Observable<unknown> =>
  new Observable((subscriber) => {
    promise.then(
      (value) => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      (err) => subscriber.error(err)
    );
  });

const AuthProvider = ({children}: PropsWithChildren) => {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);

  const checkAuthState = useCallback(() => {
    const storedAccessToken = localStorage.getItem(localStorageAccessTokenKey);
    const storedRefreshToken = localStorage.getItem(
      localStorageRefreshTokenKey
    );

    if (storedAccessToken && storedRefreshToken) {
      setAccessToken(storedAccessToken);
      setRefreshToken(storedRefreshToken);
      setIsAuthenticated(true);
    } else {
      setAccessToken(null);
      setRefreshToken(null);
      setIsAuthenticated(false);
    }
  }, []);

  useEffect(() => {
    checkAuthState();
  }, [checkAuthState]);

  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem(localStorageAccessTokenKey)
  );

  const getNewTokens = async () =>
    new Promise<NewTokens | undefined>(() => ({
      // to do: implement refresh token.
      newAccessToken: '',
      newRefreshToken: '',
    }));

  const setTokens = useCallback(
    (newAccessToken: string, newRefreshToken: string) => {
      localStorage.setItem(localStorageAccessTokenKey, newAccessToken);
      localStorage.setItem(localStorageRefreshTokenKey, newRefreshToken);
      setAccessToken(newAccessToken);
      setRefreshToken(newRefreshToken);
      setIsAuthenticated(true);
    },
    []
  );

  const removeTokens = useCallback(() => {
    localStorage.removeItem(localStorageAccessTokenKey);
    localStorage.removeItem(localStorageRefreshTokenKey);
    setAccessToken(null);
    setRefreshToken(null);
    setIsAuthenticated(false);
  }, []);

  // Functions
  const logout = useCallback(async (): Promise<void> => {
    const gapiAuth2 = window.gapi?.auth2;
    // if you are not logged in with google api just remove the tokens
    if (!gapiAuth2) {
      removeTokens();
      return;
    }
    const auth2Instance: ExtendedAuth = gapiAuth2.getAuthInstance();

    // otherwise sign out from it first
    // auth2Instance isn't a promise, thanks eslint
    if (!auth2Instance) return;
    await auth2Instance.signOut();
    auth2Instance.disconnect();
    removeTokens();
  }, [removeTokens]);

  const login = useCallback(
    async (newAccessToken: string, newRefreshToken: string) => {
      setTokens(newAccessToken, newRefreshToken);
    },
    [setTokens]
  );

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        accessToken,
        refreshToken,
        logout,
        login,
        getNewTokens,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
