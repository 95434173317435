import {Checkbox, toast, Button} from '@cashiaApp/web-components';
import React, {useEffect, useMemo} from 'react';
import Dropzone from 'react-dropzone';

import FormInput from './FormInput';
import {ReactComponent as CloseModalIcon} from '../../../assets/icons/close-modal.svg';
import {ReactComponent as DeleteIcon} from '../../../assets/icons/delete_icon.svg';
import {ReactComponent as DropdownIcon} from '../../../assets/icons/dropdown.svg';
import {ReactComponent as Info} from '../../../assets/icons/info-circle.svg';
import {ReactComponent as ModalUploadIcon} from '../../../assets/icons/modal-upload-icon.svg';
import {ReactComponent as TickIcon} from '../../../assets/icons/tick.svg';
import {ReactComponent as UploadIcon} from '../../../assets/icons/uploadIcon.svg';
import PlacesAutocomplete from '../../../components/common/AddressInput';
import DropDown from '../../../components/common/DropDown';
import Modal from '../../../components/tailwind/Modal';
import Spinner from '../../../components/tailwind/Spinner';
import {
  AddressInput,
  BusinessOnboardingOwnerInput,
  Media,
  useUploadMediaMutation,
  VerificationIdType,
} from '../../../graphql/generated';
import {cn} from '../../../utils/reusableFunctions';
import {useSelfOnboardingContentContext} from '../layout';

interface FormErrors {
  [key: string]: string;
}

type ValidateFunction = (
  values: BusinessOnboardingOwnerInput & {idFile?: File}
) => FormErrors;

const validate: ValidateFunction = (
  data: BusinessOnboardingOwnerInput & {idFile?: File}
) => {
  const errors: FormErrors = {};
  if (!data?.firstName?.trim()) {
    errors.firstName = 'First name is required';
  }
  if (!data?.lastName?.trim()) {
    errors.lastName = 'Last name is required';
  }
  if (!data?.idType?.trim()) {
    errors.idType = 'ID type is required';
  }
  if (!data?.idFile) {
    errors.id = 'Identity document is required';
  }
  if (!data?.address?.location?.trim()) {
    errors.address = 'Address is required';
  }

  return errors;
};

const idTypes = [
  VerificationIdType.NationalId,
  VerificationIdType.Passport,
  VerificationIdType.AlienCard,
];

const readableVerificationIdType = (type?: VerificationIdType) => {
  if (!type) return [];
  return {
    NATIONAL_ID: 'National ID',
    ALIEN_CARD: 'Alien Card',
    PASSPORT: 'Passport No.',
  }[type];
};

const isFile = (file: File | Media): file is File => {
  return (file as File).name !== undefined;
};

export default function BusinessOwnerInfo() {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [copyBizAddress, setCopyBizAddress] = React.useState(false);
  const [file, setFile] = React.useState<File | Media>();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [data, setData] = React.useState<BusinessOnboardingOwnerInput>(
    {} as BusinessOnboardingOwnerInput
  );
  const [errors, setErrors] = React.useState<FormErrors>({});
  const {update, onboardingData, setOnboardingData, updateLoading, goBack} =
    useSelfOnboardingContentContext();
  const [
    uploadMediaMutation,
    {
      data: uploadMediaData,
      loading: uploadMediaLoading,
      error: uploadMediaError,
    },
  ] = useUploadMediaMutation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (onboardingData && onboardingData.ownerInfo) {
      setData({...onboardingData.ownerInfo});
      setFile(onboardingData.ownerInfo.idFile);
    }
  }, [onboardingData]);

  const handleFormSubmit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (btnDisabled) return;
    setErrors({});
    const validationErrors = validate({
      ...data,
    });
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      update?.({...onboardingData, ownerInfo: {...data}});
    }
  };

  const uploadMedia = async (uploadingFile: File) => {
    const res = await uploadMediaMutation({
      variables: {file: uploadingFile},
    });
    if (res.errors) toast.error(uploadMediaError?.message);
    if (res.data?.uploadMedia.id) {
      setOnboardingData?.({
        ...onboardingData,
        ownerInfo: {
          ...data,
          idPhotos: [res.data?.uploadMedia?.id],
          idFile: uploadingFile,
        },
      });
    }
    setTimeout(() => {
      setModalOpen(false);
    }, 1000);
  };

  const btnDisabled = useMemo(() => {
    return (
      !data?.firstName ||
      !data?.lastName ||
      !data?.idType ||
      !data?.address?.location ||
      !file
    );
  }, [data, file]);

  const renderErrorMessage = (fieldName: string) => {
    if (errors[fieldName]) {
      return (
        <div className="flex items-center h-[24px]">
          <Info />
          <span className="text-orangeDark mx-2 p-1 rounded text-[12px] font-normal bg-lightOrange">
            {errors[fieldName]}
          </span>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="flex w-full h-full max-md:h-[95%]">
      <form className="flex flex-col justify-between">
        <div>
          <p className="font-[600]">Step 2:</p>
          <p className="mb-4 font-[600] text-[40px] leading-[40px] max-md:text-[24px] max-md:leading-[24px] max-md:w-[300px] w-[65%]">
            Business Owner Information
          </p>
          <FormInput
            label="First Name"
            required
            error={renderErrorMessage('firstName')}
            moreInfo="First name must match identity document"
            onChange={(e) => setData({...data, firstName: e})}
            value={data?.firstName?.replaceAll(' ', '')}
          />
          <FormInput
            label="Last Name"
            required
            error={renderErrorMessage('lastName')}
            moreInfo="Last name must match identity document"
            onChange={(e) => setData({...data, lastName: e})}
            value={data?.lastName?.replaceAll(' ', '')}
          />
          <div className="mb-4">
            <p className="flex font-[600] mb-1 gap-2 items-center text-sm">
              Identification Document Type * {renderErrorMessage('idType')}
            </p>
            <DropDown
              dropDownPosition="center"
              show={dropdownOpen}
              setShow={setDropdownOpen}
              actionElement={
                <div
                  id="id-type-dropdown"
                  className={`${
                    dropdownOpen ? 'border-red-300' : 'border-dividerGrey'
                  } px-[10px] flex flex-row items-center justify-between w-[497px] max-md:w-[88vw] bg-transparent border-[1px] rounded-[5px] h-[46px] focus:outline-none focus:ring-0 focus:border-red-300`}>
                  <p className="w-[450px] overflow-hidden truncate text-[14px]">
                    {readableVerificationIdType(data.idType || undefined)}
                  </p>
                  <DropdownIcon />
                </div>
              }>
              <div className="shadow-sm cursor-pointer max-md:w-[88vw] w-[497px] h-[140px] overflow-y-scroll ">
                {Object.values(VerificationIdType).map((item, i) => (
                  <div
                    id="id-type"
                    onClick={() => {
                      setDropdownOpen(false);
                      setData({...data, idType: item});
                    }}
                    key={item}
                    className={`hover:text-red-500 cursor-pointer flex items-center bg-white px-4 py-2 ${
                      i !== idTypes.length - 1
                        ? 'border-b-[1px] border-dividerGrey'
                        : ''
                    } px-3 py-3`}>
                    <p className="text-[14px] font-[500]">
                      {readableVerificationIdType(item)}
                    </p>
                  </div>
                ))}
              </div>
            </DropDown>
          </div>

          {errors.id && <div className="mb-2">{renderErrorMessage('id')}</div>}
          <div className="flex items-center gap-4 mb-[15px]">
            <div
              onClick={() => {
                setModalOpen(true);
              }}
              className={`cursor-pointer flex items-center justify-center w-[138px] h-[88px] border-[1.2px] rounded-[10px] ${
                errors.id
                  ? 'border-red-300 border-[1px]'
                  : 'border-lightenedGrey'
              }`}>
              {file ? (
                <div>
                  <img
                    alt=""
                    className="cursor-pointer h-[88px] w-[150px] rounded-[5px]"
                    src={isFile(file) ? URL.createObjectURL(file) : file.url}
                  />
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <UploadIcon />
                </div>
              )}
            </div>
            <div>
              <p className="text-[14px] font-[500] leading--[14px]">
                Copy of Valid Proof of Identity
              </p>
              <p className="text-[12px] font-[500] leading--[12px] text-greyish">
                PNG, JPG up to 5MB
              </p>
              <div
                onClick={() => setModalOpen(true)}
                className="cursor-pointer">
                <p className="text-[12px] font-[600] leading--[12px] text-disabledText">
                  {onboardingData?.ownerInfo?.idFile
                    ? 'Update Image'
                    : 'Upload Image'}
                </p>
              </div>
            </div>
          </div>
          <div>
            <p className="flex font-[600] mb-1 gap-2 items-center text-sm">
              Residential Address * {renderErrorMessage('address')}
            </p>
            <PlacesAutocomplete
              onSelect={(value) => {
                setCopyBizAddress(false);
                setData({
                  ...data,
                  address: {
                    ...data.address,
                    location: value,
                    coordinates: {
                      lat: 0,
                      long: 0,
                    },
                  },
                });
              }}
              defaultValue={data.address?.location || ''}
            />
            <div className="flex items-center gap-2 text-[14px] mb-[15px]">
              <Checkbox
                checked={
                  copyBizAddress ||
                  data?.address?.location ===
                    onboardingData?.buisnessInfo?.location ||
                  data?.address === onboardingData?.buisnessInfo?.location
                }
                onChange={() => {
                  if (!copyBizAddress) {
                    setData({
                      ...data,
                      address: {
                        ...data.address,
                        location: onboardingData?.buisnessInfo?.location || '',
                        coordinates: {
                          lat: 0,
                          long: 0,
                        },
                      },
                    });
                  } else {
                    setData({...data, address: {} as AddressInput});
                  }
                  setCopyBizAddress(!copyBizAddress);
                }}
                className="w-[14px] h-[14px] border-dividerGrey"
              />
              <p className="font-[400] text-[14px]">
                Is this the same as business address
              </p>
            </div>
          </div>

          <FormInput
            label="Complex / Building Name"
            onChange={(e) =>
              setData({
                ...data,
                address: {
                  ...data.address,
                  location: data.address?.location || '',
                  buildingName: e,
                  coordinates: {
                    lat: 0,
                    long: 0,
                  },
                },
              })
            }
            value={data.address?.buildingName || ''}
          />
        </div>
        <div className="flex flex-row justify-between max-md:w-full w-[497px]">
          <Button
            onClick={(e) => {
              e.preventDefault();
              goBack?.();
            }}
            className="cursor-pointer max-md:w-[143px] w-[237px] h-[48px] border-lightRed border-[1.2px] rounded-[8px] flex items-center justify-center bg-white">
            <p className="text-active-red font-[600]">Previous</p>
          </Button>
          <Button
            id="nextBtn"
            className={cn(
              'max-md:w-[143px] w-[237px] h-[48px] rounded-[8px] flex items-center justify-center font-[600] bg-pink text-white',
              {
                'text-disabledText bg-lightGrey cursor-default': btnDisabled,
              }
            )}
            onClick={handleFormSubmit}>
            {updateLoading ? <Spinner className="fill-white" /> : 'Next'}
          </Button>
        </div>
      </form>
      <Modal
        isVisible={modalOpen}
        onClose={() => setModalOpen(false)}
        className="w-[90%] max-w-lg md:max-w-xl lg:max-w-2xl h-auto p-4">
        <div className="flex flex-col w-full h-auto max-md:py-1 max-md:px-2 py-6 px-6 bg-lightGrey rounded-lg">
          <div className="flex justify-between">
            <div>
              <p className="font-semibold text-xl md:text-2xl leading-snug pb-2">
                Upload a Valid Proof of Identity
              </p>
              <p className="font-normal text-sm md:text-base text-foggy">
                Provide a valid ID document
              </p>
            </div>
            <CloseModalIcon
              className="cursor-pointer"
              onClick={() => setModalOpen(false)}
            />
          </div>

          {file ? (
            <div className="w-full h-72 md:h-80 border-2 border-dashed border-gray-300 rounded-md flex flex-col justify-center items-center relative cursor-pointer p-4 mt-4 bg-white">
              <div
                className="absolute top-2 right-2 cursor-pointer"
                onClick={() => setFile(undefined)}>
                <DeleteIcon />
              </div>
              <div className="cursor-pointer h-[200px]] w-[237px] py-3 rounded-[5px]">
                <img
                  alt=""
                  className="cursor-pointer h-[200px] w-[237px] rounded-[5px]"
                  src={isFile(file) ? URL.createObjectURL(file) : file.url}
                />
                {uploadMediaData?.uploadMedia.id &&
                  file === onboardingData?.ownerInfo?.idFile && (
                    <div className="flex items-center gap-3 mt-2">
                      <TickIcon />
                      <p className="font-semibold text-foggy">
                        Upload Successful!
                      </p>
                    </div>
                  )}
              </div>
            </div>
          ) : (
            <Dropzone
              accept={{
                'image/*': ['.jpeg', '.png', '.jpg'],
              }}
              minSize={0}
              maxSize={5242880}
              onDrop={async (acceptedFiles) => {
                if (acceptedFiles.length) {
                  setFile(acceptedFiles[0]);
                }
              }}
              onDropRejected={() => {
                toast.error('Image too large. Maximum size is 5MB.');
              }}>
              {({getRootProps, getInputProps}) => (
                <div
                  className="w-full h-72 md:h-80 border-2 border-dashed border-gray-300 rounded-md flex flex-col justify-center items-center relative cursor-pointer p-4 mt-4 bg-white"
                  {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="flex justify-start p-[10px] mb-[10px] w-full flex-col items-center gap-3">
                    <ModalUploadIcon width={48} />
                    <p className="text-base md:text-lg font-medium text-disabledText text-center">
                      Drag and drop file here or{' '}
                      <span className="text-pink">Choose file</span>
                    </p>
                  </div>
                </div>
              )}
            </Dropzone>
          )}

          <div className="flex justify-between w-full my-4">
            <p className="text-sm md:text-base max-md:w-[163px] max-md:text-[14px]">
              SUPPORTED FORMATS: PNG, JPEG
            </p>
            <p className="text-sm md:text-base max-md:text-[14px] max-md:text-right">
              MAXIMUM SIZE: 5MB
            </p>
          </div>

          <div className="flex justify-between">
            <Button
              onClick={() => setModalOpen(false)}
              className="w-32 md:w-48 h-12 border-lightRed border rounded-lg bg-white">
              <p className="text-active-red font-semibold">Cancel</p>
            </Button>
            <Button
              onClick={() => {
                file && isFile(file) && void uploadMedia(file);
              }}
              className={`w-32 md:w-48 h-12 rounded-lg ${
                !file ? 'bg-lightenedGrey' : 'bg-smoothRed text-white'
              }`}>
              <p
                className={`${
                  !file ? 'text-disabledText' : 'text-white'
                } font-[600]`}>
                {uploadMediaLoading ? (
                  <Spinner className="fill-white" />
                ) : (
                  'Upload'
                )}
              </p>
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
