import {Avatar} from '@cashiaApp/web-components';

import ChevronDown from '../../assets/icons/chevron_down.svg';
import {mockUser} from '../../utils/mocks/resolvers';

const Toolbar = () => {
  return (
    <div className="flex flex-row h-full items-center justify-end">
      <div className="border border-Greyish w-20 h-11 rounded-[10px] flex justify-center items-center">
        <img src={ChevronDown} />
        <Avatar
          src={mockUser?.logo?.small || ''}
          className="rounded-full overflow-hidden w-[34px] h-[34px] ml-1"
        />
      </div>
    </div>
  );
};

export default Toolbar;
