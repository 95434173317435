import {Button} from '@cashiaApp/web-components';
import React from 'react';
import {useNavigate} from 'react-router-dom';

const Success = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-[85vh] bg-white lg:bg-lightGrey rounded-3xl p-4 lg:p-6 flex flex-col">
      <div
        className="flex-grow bg-white w-full mx-auto rounded-3xl overflow-hidden flex flex-col justify-center items-center px-4"
        style={{
          boxShadow: `
            0 0px 0px rgba(0, 0, 0, 0),
            0 0px 0px rgba(0, 0, 0, 0),
            0 8px 4px rgba(0, 0, 0, 0.03),
            0 16px 8px rgba(0, 0, 0, 0.04),
            0 32px 16px rgba(0, 0, 0, 0.05)
          `,
        }}>
        <div className="flex flex-col items-center justify-center text-center gap-[32px] px-4 w-full max-w-xl mx-auto py-8">
          <p className="flex justify-center text-6xl">🎉</p>
          <p className="font-metropolis font-semibold lg:font-bold text-4xl leading-10 text-black lg:text-4xl">
            You're All Ready To Go!
          </p>
          <div className="flex flex-col justify-between items-center gap-40 lg:gap-10">
            <div className="flex flex-col gap-2 text-center">
              <p className="font-Metropolis text-foggy px-4 md:px-0">
                Now, sit back, relax, and keep an eye out for your review
                status. We'll update you soon—exciting things are on the way!
                👀✨
              </p>
            </div>
            <Button
              type="submit"
              onClick={() => {
                navigate('/home');
              }}
              className="bg-smoothRed text-white w-[303px] rounded-lg max-w-xs md:max-w-md h-12 mt-auto sm:mt-16 lg:mt-8">
              Back to Home
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
