import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  TimeInHm: { input: string; output: string; }
  Upload: { input: any; output: any; }
};

export type Address = {
  __typename?: 'Address';
  buildingName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  location: Location;
};

export type AddressInput = {
  buildingName?: InputMaybe<Scalars['String']['input']>;
  coordinates: GeoCoordinatesInput;
  id?: InputMaybe<Scalars['ID']['input']>;
  location: Scalars['String']['input'];
};

export type Bank = {
  __typename?: 'Bank';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type BankAccount = {
  __typename?: 'BankAccount';
  accountHolder: Scalars['String']['output'];
  accountNumber: Scalars['String']['output'];
  bank: Bank;
  id: Scalars['ID']['output'];
};

export type Business = {
  __typename?: 'Business';
  address: Address;
  bankAccount: BankAccount;
  category: BusinessCategory;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Users associated with the business. Only has the user that submitted the form for now */
  members: Array<User>;
  name: Scalars['String']['output'];
  owner: BusinessOwner;
  phoneNumber: PhoneNumber;
  socialMedia: BusinessSocialMedia;
};

export type BusinessCategory = {
  __typename?: 'BusinessCategory';
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type BusinessOnboarding = {
  __typename?: 'BusinessOnboarding';
  address?: Maybe<Address>;
  bankAccountBank?: Maybe<Bank>;
  bankAccountHolder?: Maybe<Scalars['String']['output']>;
  bankAccountNumber?: Maybe<Scalars['String']['output']>;
  category?: Maybe<BusinessCategory>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  ownerAddress?: Maybe<Address>;
  ownerFirstName?: Maybe<Scalars['String']['output']>;
  ownerIdPhotos?: Maybe<Array<Media>>;
  ownerIdType?: Maybe<VerificationIdType>;
  ownerLastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<PhoneNumber>;
  socialMedia?: Maybe<BusinessSocialMedia>;
  user: User;
};

export type BusinessOnboardingBankAccountInput = {
  accountHolder?: InputMaybe<Scalars['String']['input']>;
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['ID']['input']>;
};

export type BusinessOnboardingInput = {
  address?: InputMaybe<AddressInput>;
  bankAccount?: InputMaybe<BusinessOnboardingBankAccountInput>;
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<BusinessOnboardingOwnerInput>;
  phoneNumber?: InputMaybe<PhoneNumberInput>;
  socialMedia?: InputMaybe<BusinessOnboardingSocialMediaInput>;
};

export type BusinessOnboardingOwnerInput = {
  address?: InputMaybe<AddressInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  idPhotos?: InputMaybe<Array<Scalars['ID']['input']>>;
  idType?: InputMaybe<VerificationIdType>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type BusinessOnboardingSocialMediaInput = {
  facebook?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  snapchat?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type BusinessOwner = {
  __typename?: 'BusinessOwner';
  address: Address;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  idPhotos: Array<Media>;
  idType: VerificationIdType;
  lastName: Scalars['String']['output'];
};

export type BusinessSocialMedia = {
  __typename?: 'BusinessSocialMedia';
  facebook?: Maybe<Scalars['String']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  snapchat?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type Error = {
  type: ErrorTypes;
};

export enum ErrorTypes {
  AccountLocked = 'ACCOUNT_LOCKED',
  NotAuthorized = 'NOT_AUTHORIZED',
  NotFound = 'NOT_FOUND',
  NotRegistered = 'NOT_REGISTERED',
  /** Protected field: Only Admins and owners can access the field */
  ProtectedField = 'PROTECTED_FIELD',
  /** Service error, please retry */
  ServerError = 'SERVER_ERROR',
  TokenExpired = 'TOKEN_EXPIRED',
  TokenInvalid = 'TOKEN_INVALID',
  ValidationError = 'VALIDATION_ERROR'
}

export type GeoCoordinates = {
  __typename?: 'GeoCoordinates';
  lat: Scalars['Float']['output'];
  long: Scalars['Float']['output'];
};

export type GeoCoordinatesInput = {
  lat: Scalars['Float']['input'];
  long: Scalars['Float']['input'];
};

export type Image = {
  __typename?: 'Image';
  id?: Maybe<Scalars['ID']['output']>;
  large?: Maybe<Scalars['String']['output']>;
  medium?: Maybe<Scalars['String']['output']>;
  small?: Maybe<Scalars['String']['output']>;
};

export type Location = {
  __typename?: 'Location';
  coordinates: GeoCoordinates;
  formattedAddress: Scalars['String']['output'];
};

export type Login = {
  __typename?: 'Login';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
  user: User;
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LogoutInput = {
  refreshToken: Scalars['String']['input'];
};

export type Media = {
  __typename?: 'Media';
  contentType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  countryCode: Scalars['String']['output'];
  number: Scalars['String']['output'];
};

export type PhoneNumberInput = {
  countryCode: Scalars['String']['input'];
  number: Scalars['String']['input'];
};

export type RefreshTokenInput = {
  refreshToken: Scalars['String']['input'];
};

export type RootMutationType = {
  __typename?: 'RootMutationType';
  /** User login */
  login?: Maybe<Login>;
  /** Logout */
  logout?: Maybe<Scalars['Boolean']['output']>;
  /** Refresh Token */
  refreshToken?: Maybe<Login>;
  /** User registration */
  signup?: Maybe<User>;
  submitBusinessOnboarding: Scalars['Boolean']['output'];
  updateBusinessOnboarding: BusinessOnboarding;
  uploadMedia: Media;
};


export type RootMutationTypeLoginArgs = {
  input?: InputMaybe<LoginInput>;
};


export type RootMutationTypeLogoutArgs = {
  input?: InputMaybe<LogoutInput>;
};


export type RootMutationTypeRefreshTokenArgs = {
  input?: InputMaybe<RefreshTokenInput>;
};


export type RootMutationTypeSignupArgs = {
  input?: InputMaybe<SignupInput>;
};


export type RootMutationTypeUpdateBusinessOnboardingArgs = {
  input: BusinessOnboardingInput;
};


export type RootMutationTypeUploadMediaArgs = {
  file: Scalars['Upload']['input'];
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  banks: Array<Bank>;
  /** Returns the business associated with the current user, if it exists */
  business?: Maybe<Business>;
  businessCategories: Array<BusinessCategory>;
  businessOnboarding?: Maybe<BusinessOnboarding>;
  /** This is needed to import the error type into the Graphql docs [for Absinthee] */
  error?: Maybe<ValidationError>;
  /** Get current user */
  me?: Maybe<User>;
};

export type SignupInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone: PhoneNumberInput;
};

export type User = {
  __typename?: 'User';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Image>;
  phone?: Maybe<PhoneNumber>;
};

export type ValidationError = Error & {
  __typename?: 'ValidationError';
  key: Scalars['String']['output'];
  /** Should give details sometimes. Example: a @cashia tag is already taken. */
  message?: Maybe<Scalars['String']['output']>;
  type: ErrorTypes;
};

/** Type of identity verification document */
export enum VerificationIdType {
  AlienCard = 'ALIEN_CARD',
  NationalId = 'NATIONAL_ID',
  Passport = 'PASSPORT'
}

export type GeoCoordinatesFieldsFragment = { __typename?: 'GeoCoordinates', lat: number, long: number };

export type LocationFieldsFragment = { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } };

export type AddressFieldsFragment = { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } };

export type BanksFieldsFragment = { __typename?: 'Bank', id: string, name: string };

export type BusinessOwnerFieldsFragment = { __typename?: 'BusinessOwner', id: string, firstName: string, lastName: string, idType: VerificationIdType, idPhotos: Array<{ __typename?: 'Media', id: string }>, address: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } } };

export type BusinessSocialMediaFieldsFragment = { __typename?: 'BusinessSocialMedia', website?: string | null, facebook?: string | null, instagram?: string | null, snapchat?: string | null };

export type BankAccountFieldsFragment = { __typename?: 'BankAccount', id: string, accountHolder: string, accountNumber: string, bank: { __typename?: 'Bank', id: string, name: string } };

export type BusinessFragment = { __typename?: 'Business', id: string, name: string, description: string, phoneNumber: { __typename?: 'PhoneNumber', number: string, countryCode: string }, category: { __typename?: 'BusinessCategory', id: string, name: string, code: string }, address: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } }, owner: { __typename?: 'BusinessOwner', id: string, firstName: string, lastName: string, idType: VerificationIdType, idPhotos: Array<{ __typename?: 'Media', id: string }>, address: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } } }, bankAccount: { __typename?: 'BankAccount', id: string, accountHolder: string, accountNumber: string, bank: { __typename?: 'Bank', id: string, name: string } }, socialMedia: { __typename?: 'BusinessSocialMedia', website?: string | null, facebook?: string | null, instagram?: string | null, snapchat?: string | null } };

export type BusinessCategoryFieldsFragment = { __typename?: 'BusinessCategory', id: string, name: string, code: string };

export type BusinessOnboardingFieldsFragment = { __typename?: 'BusinessOnboarding', id: string, name?: string | null, description?: string | null, ownerFirstName?: string | null, ownerLastName?: string | null, ownerIdType?: VerificationIdType | null, bankAccountHolder?: string | null, bankAccountNumber?: string | null, category?: { __typename?: 'BusinessCategory', id: string, name: string, code: string } | null, phoneNumber?: { __typename?: 'PhoneNumber', number: string, countryCode: string } | null, address?: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } } | null, ownerIdPhotos?: Array<{ __typename?: 'Media', id: string, url: string, contentType: string }> | null, ownerAddress?: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } } | null, bankAccountBank?: { __typename?: 'Bank', id: string, name: string } | null, socialMedia?: { __typename?: 'BusinessSocialMedia', website?: string | null, facebook?: string | null, instagram?: string | null, snapchat?: string | null } | null };

export type ImageFieldsFragment = { __typename?: 'Image', id?: string | null, small?: string | null, medium?: string | null, large?: string | null };

export type PhoneNumberFieldsFragment = { __typename?: 'PhoneNumber', number: string, countryCode: string };

export type UserFieldsFragment = { __typename?: 'User', id?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null, createdAt?: any | null, phone?: { __typename?: 'PhoneNumber', number: string, countryCode: string } | null };

export type SubmitBusinessOnboardingMutationVariables = Exact<{ [key: string]: never; }>;


export type SubmitBusinessOnboardingMutation = { __typename?: 'RootMutationType', submitBusinessOnboarding: boolean };

export type UpdateBusinessOnboardingMutationVariables = Exact<{
  input: BusinessOnboardingInput;
}>;


export type UpdateBusinessOnboardingMutation = { __typename?: 'RootMutationType', updateBusinessOnboarding: { __typename?: 'BusinessOnboarding', id: string, name?: string | null, description?: string | null, ownerFirstName?: string | null, ownerLastName?: string | null, ownerIdType?: VerificationIdType | null, bankAccountHolder?: string | null, bankAccountNumber?: string | null, category?: { __typename?: 'BusinessCategory', id: string, name: string, code: string } | null, phoneNumber?: { __typename?: 'PhoneNumber', number: string, countryCode: string } | null, address?: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } } | null, ownerIdPhotos?: Array<{ __typename?: 'Media', id: string, url: string, contentType: string }> | null, ownerAddress?: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } } | null, bankAccountBank?: { __typename?: 'Bank', id: string, name: string } | null, socialMedia?: { __typename?: 'BusinessSocialMedia', website?: string | null, facebook?: string | null, instagram?: string | null, snapchat?: string | null } | null } };

export type UploadMediaMutationVariables = Exact<{
  file: Scalars['Upload']['input'];
}>;


export type UploadMediaMutation = { __typename?: 'RootMutationType', uploadMedia: { __typename?: 'Media', id: string } };

export type GetBanksQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBanksQuery = { __typename?: 'RootQueryType', banks: Array<{ __typename?: 'Bank', id: string, name: string }> };

export type GetBusinessCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBusinessCategoriesQuery = { __typename?: 'RootQueryType', businessCategories: Array<{ __typename?: 'BusinessCategory', id: string, name: string, code: string }> };

export type GetBusinessOnboardingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBusinessOnboardingQuery = { __typename?: 'RootQueryType', businessOnboarding?: { __typename?: 'BusinessOnboarding', id: string, name?: string | null, description?: string | null, ownerFirstName?: string | null, ownerLastName?: string | null, ownerIdType?: VerificationIdType | null, bankAccountHolder?: string | null, bankAccountNumber?: string | null, category?: { __typename?: 'BusinessCategory', id: string, name: string, code: string } | null, phoneNumber?: { __typename?: 'PhoneNumber', number: string, countryCode: string } | null, address?: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } } | null, ownerIdPhotos?: Array<{ __typename?: 'Media', id: string, url: string, contentType: string }> | null, ownerAddress?: { __typename?: 'Address', id: string, buildingName?: string | null, location: { __typename?: 'Location', formattedAddress: string, coordinates: { __typename?: 'GeoCoordinates', lat: number, long: number } } } | null, bankAccountBank?: { __typename?: 'Bank', id: string, name: string } | null, socialMedia?: { __typename?: 'BusinessSocialMedia', website?: string | null, facebook?: string | null, instagram?: string | null, snapchat?: string | null } | null } | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'RootQueryType', me?: { __typename?: 'User', id?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null, createdAt?: any | null, phone?: { __typename?: 'PhoneNumber', number: string, countryCode: string } | null } | null };

export const PhoneNumberFieldsFragmentDoc = gql`
    fragment phoneNumberFields on PhoneNumber {
  number
  countryCode
}
    `;
export const BusinessCategoryFieldsFragmentDoc = gql`
    fragment businessCategoryFields on BusinessCategory {
  id
  name
  code
}
    `;
export const GeoCoordinatesFieldsFragmentDoc = gql`
    fragment geoCoordinatesFields on GeoCoordinates {
  lat
  long
}
    `;
export const LocationFieldsFragmentDoc = gql`
    fragment locationFields on Location {
  formattedAddress
  coordinates {
    ...geoCoordinatesFields
  }
}
    ${GeoCoordinatesFieldsFragmentDoc}`;
export const AddressFieldsFragmentDoc = gql`
    fragment addressFields on Address {
  id
  location {
    ...locationFields
  }
  buildingName
}
    ${LocationFieldsFragmentDoc}`;
export const BusinessOwnerFieldsFragmentDoc = gql`
    fragment businessOwnerFields on BusinessOwner {
  id
  firstName
  lastName
  idType
  idPhotos {
    id
  }
  address {
    ...addressFields
  }
}
    ${AddressFieldsFragmentDoc}`;
export const BanksFieldsFragmentDoc = gql`
    fragment banksFields on Bank {
  id
  name
}
    `;
export const BankAccountFieldsFragmentDoc = gql`
    fragment bankAccountFields on BankAccount {
  id
  bank {
    ...banksFields
  }
  accountHolder
  accountNumber
}
    ${BanksFieldsFragmentDoc}`;
export const BusinessSocialMediaFieldsFragmentDoc = gql`
    fragment businessSocialMediaFields on BusinessSocialMedia {
  website
  facebook
  instagram
  snapchat
}
    `;
export const BusinessFragmentDoc = gql`
    fragment business on Business {
  id
  name
  description
  phoneNumber {
    ...phoneNumberFields
  }
  category {
    ...businessCategoryFields
  }
  address {
    ...addressFields
  }
  owner {
    ...businessOwnerFields
  }
  bankAccount {
    ...bankAccountFields
  }
  socialMedia {
    ...businessSocialMediaFields
  }
}
    ${PhoneNumberFieldsFragmentDoc}
${BusinessCategoryFieldsFragmentDoc}
${AddressFieldsFragmentDoc}
${BusinessOwnerFieldsFragmentDoc}
${BankAccountFieldsFragmentDoc}
${BusinessSocialMediaFieldsFragmentDoc}`;
export const BusinessOnboardingFieldsFragmentDoc = gql`
    fragment businessOnboardingFields on BusinessOnboarding {
  id
  name
  category {
    ...businessCategoryFields
  }
  description
  phoneNumber {
    ...phoneNumberFields
  }
  address {
    ...addressFields
  }
  ownerFirstName
  ownerLastName
  ownerIdType
  ownerIdPhotos {
    id
    url
    contentType
  }
  ownerAddress {
    ...addressFields
  }
  bankAccountBank {
    id
    name
  }
  bankAccountHolder
  bankAccountNumber
  socialMedia {
    ...businessSocialMediaFields
  }
}
    ${BusinessCategoryFieldsFragmentDoc}
${PhoneNumberFieldsFragmentDoc}
${AddressFieldsFragmentDoc}
${BusinessSocialMediaFieldsFragmentDoc}`;
export const ImageFieldsFragmentDoc = gql`
    fragment imageFields on Image {
  id
  small
  medium
  large
}
    `;
export const UserFieldsFragmentDoc = gql`
    fragment userFields on User {
  id
  firstName
  lastName
  email
  phone {
    ...phoneNumberFields
  }
  createdAt
}
    ${PhoneNumberFieldsFragmentDoc}`;
export const SubmitBusinessOnboardingDocument = gql`
    mutation SubmitBusinessOnboarding {
  submitBusinessOnboarding
}
    `;
export type SubmitBusinessOnboardingMutationFn = Apollo.MutationFunction<SubmitBusinessOnboardingMutation, SubmitBusinessOnboardingMutationVariables>;

/**
 * __useSubmitBusinessOnboardingMutation__
 *
 * To run a mutation, you first call `useSubmitBusinessOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitBusinessOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitBusinessOnboardingMutation, { data, loading, error }] = useSubmitBusinessOnboardingMutation({
 *   variables: {
 *   },
 * });
 */
export function useSubmitBusinessOnboardingMutation(baseOptions?: Apollo.MutationHookOptions<SubmitBusinessOnboardingMutation, SubmitBusinessOnboardingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitBusinessOnboardingMutation, SubmitBusinessOnboardingMutationVariables>(SubmitBusinessOnboardingDocument, options);
      }
export type SubmitBusinessOnboardingMutationHookResult = ReturnType<typeof useSubmitBusinessOnboardingMutation>;
export type SubmitBusinessOnboardingMutationResult = Apollo.MutationResult<SubmitBusinessOnboardingMutation>;
export type SubmitBusinessOnboardingMutationOptions = Apollo.BaseMutationOptions<SubmitBusinessOnboardingMutation, SubmitBusinessOnboardingMutationVariables>;
export const UpdateBusinessOnboardingDocument = gql`
    mutation UpdateBusinessOnboarding($input: BusinessOnboardingInput!) {
  updateBusinessOnboarding(input: $input) {
    ...businessOnboardingFields
  }
}
    ${BusinessOnboardingFieldsFragmentDoc}`;
export type UpdateBusinessOnboardingMutationFn = Apollo.MutationFunction<UpdateBusinessOnboardingMutation, UpdateBusinessOnboardingMutationVariables>;

/**
 * __useUpdateBusinessOnboardingMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessOnboardingMutation, { data, loading, error }] = useUpdateBusinessOnboardingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBusinessOnboardingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBusinessOnboardingMutation, UpdateBusinessOnboardingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBusinessOnboardingMutation, UpdateBusinessOnboardingMutationVariables>(UpdateBusinessOnboardingDocument, options);
      }
export type UpdateBusinessOnboardingMutationHookResult = ReturnType<typeof useUpdateBusinessOnboardingMutation>;
export type UpdateBusinessOnboardingMutationResult = Apollo.MutationResult<UpdateBusinessOnboardingMutation>;
export type UpdateBusinessOnboardingMutationOptions = Apollo.BaseMutationOptions<UpdateBusinessOnboardingMutation, UpdateBusinessOnboardingMutationVariables>;
export const UploadMediaDocument = gql`
    mutation UploadMedia($file: Upload!) {
  uploadMedia(file: $file) {
    id
  }
}
    `;
export type UploadMediaMutationFn = Apollo.MutationFunction<UploadMediaMutation, UploadMediaMutationVariables>;

/**
 * __useUploadMediaMutation__
 *
 * To run a mutation, you first call `useUploadMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadMediaMutation, { data, loading, error }] = useUploadMediaMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadMediaMutation(baseOptions?: Apollo.MutationHookOptions<UploadMediaMutation, UploadMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadMediaMutation, UploadMediaMutationVariables>(UploadMediaDocument, options);
      }
export type UploadMediaMutationHookResult = ReturnType<typeof useUploadMediaMutation>;
export type UploadMediaMutationResult = Apollo.MutationResult<UploadMediaMutation>;
export type UploadMediaMutationOptions = Apollo.BaseMutationOptions<UploadMediaMutation, UploadMediaMutationVariables>;
export const GetBanksDocument = gql`
    query GetBanks {
  banks {
    ...banksFields
  }
}
    ${BanksFieldsFragmentDoc}`;

/**
 * __useGetBanksQuery__
 *
 * To run a query within a React component, call `useGetBanksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBanksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBanksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBanksQuery(baseOptions?: Apollo.QueryHookOptions<GetBanksQuery, GetBanksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBanksQuery, GetBanksQueryVariables>(GetBanksDocument, options);
      }
export function useGetBanksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBanksQuery, GetBanksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBanksQuery, GetBanksQueryVariables>(GetBanksDocument, options);
        }
export function useGetBanksSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetBanksQuery, GetBanksQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBanksQuery, GetBanksQueryVariables>(GetBanksDocument, options);
        }
export type GetBanksQueryHookResult = ReturnType<typeof useGetBanksQuery>;
export type GetBanksLazyQueryHookResult = ReturnType<typeof useGetBanksLazyQuery>;
export type GetBanksSuspenseQueryHookResult = ReturnType<typeof useGetBanksSuspenseQuery>;
export type GetBanksQueryResult = Apollo.QueryResult<GetBanksQuery, GetBanksQueryVariables>;
export const GetBusinessCategoriesDocument = gql`
    query GetBusinessCategories {
  businessCategories {
    ...businessCategoryFields
  }
}
    ${BusinessCategoryFieldsFragmentDoc}`;

/**
 * __useGetBusinessCategoriesQuery__
 *
 * To run a query within a React component, call `useGetBusinessCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBusinessCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetBusinessCategoriesQuery, GetBusinessCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusinessCategoriesQuery, GetBusinessCategoriesQueryVariables>(GetBusinessCategoriesDocument, options);
      }
export function useGetBusinessCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessCategoriesQuery, GetBusinessCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusinessCategoriesQuery, GetBusinessCategoriesQueryVariables>(GetBusinessCategoriesDocument, options);
        }
export function useGetBusinessCategoriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetBusinessCategoriesQuery, GetBusinessCategoriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBusinessCategoriesQuery, GetBusinessCategoriesQueryVariables>(GetBusinessCategoriesDocument, options);
        }
export type GetBusinessCategoriesQueryHookResult = ReturnType<typeof useGetBusinessCategoriesQuery>;
export type GetBusinessCategoriesLazyQueryHookResult = ReturnType<typeof useGetBusinessCategoriesLazyQuery>;
export type GetBusinessCategoriesSuspenseQueryHookResult = ReturnType<typeof useGetBusinessCategoriesSuspenseQuery>;
export type GetBusinessCategoriesQueryResult = Apollo.QueryResult<GetBusinessCategoriesQuery, GetBusinessCategoriesQueryVariables>;
export const GetBusinessOnboardingDocument = gql`
    query GetBusinessOnboarding {
  businessOnboarding {
    ...businessOnboardingFields
  }
}
    ${BusinessOnboardingFieldsFragmentDoc}`;

/**
 * __useGetBusinessOnboardingQuery__
 *
 * To run a query within a React component, call `useGetBusinessOnboardingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessOnboardingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessOnboardingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBusinessOnboardingQuery(baseOptions?: Apollo.QueryHookOptions<GetBusinessOnboardingQuery, GetBusinessOnboardingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusinessOnboardingQuery, GetBusinessOnboardingQueryVariables>(GetBusinessOnboardingDocument, options);
      }
export function useGetBusinessOnboardingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessOnboardingQuery, GetBusinessOnboardingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusinessOnboardingQuery, GetBusinessOnboardingQueryVariables>(GetBusinessOnboardingDocument, options);
        }
export function useGetBusinessOnboardingSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetBusinessOnboardingQuery, GetBusinessOnboardingQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBusinessOnboardingQuery, GetBusinessOnboardingQueryVariables>(GetBusinessOnboardingDocument, options);
        }
export type GetBusinessOnboardingQueryHookResult = ReturnType<typeof useGetBusinessOnboardingQuery>;
export type GetBusinessOnboardingLazyQueryHookResult = ReturnType<typeof useGetBusinessOnboardingLazyQuery>;
export type GetBusinessOnboardingSuspenseQueryHookResult = ReturnType<typeof useGetBusinessOnboardingSuspenseQuery>;
export type GetBusinessOnboardingQueryResult = Apollo.QueryResult<GetBusinessOnboardingQuery, GetBusinessOnboardingQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...userFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export function useMeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;


/** Mapping of interface types */
export type ResolversInterfaceTypes<_RefType extends Record<string, unknown>> = ResolversObject<{
  Error: ( ValidationError );
}>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  Address: ResolverTypeWrapper<Address>;
  AddressInput: AddressInput;
  Bank: ResolverTypeWrapper<Bank>;
  BankAccount: ResolverTypeWrapper<BankAccount>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  Business: ResolverTypeWrapper<Business>;
  BusinessCategory: ResolverTypeWrapper<BusinessCategory>;
  BusinessOnboarding: ResolverTypeWrapper<BusinessOnboarding>;
  BusinessOnboardingBankAccountInput: BusinessOnboardingBankAccountInput;
  BusinessOnboardingInput: BusinessOnboardingInput;
  BusinessOnboardingOwnerInput: BusinessOnboardingOwnerInput;
  BusinessOnboardingSocialMediaInput: BusinessOnboardingSocialMediaInput;
  BusinessOwner: ResolverTypeWrapper<BusinessOwner>;
  BusinessSocialMedia: ResolverTypeWrapper<BusinessSocialMedia>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']['output']>;
  Error: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['Error']>;
  ErrorTypes: ErrorTypes;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  GeoCoordinates: ResolverTypeWrapper<GeoCoordinates>;
  GeoCoordinatesInput: GeoCoordinatesInput;
  ID: ResolverTypeWrapper<Scalars['ID']['output']>;
  Image: ResolverTypeWrapper<Image>;
  Location: ResolverTypeWrapper<Location>;
  Login: ResolverTypeWrapper<Login>;
  LoginInput: LoginInput;
  LogoutInput: LogoutInput;
  Media: ResolverTypeWrapper<Media>;
  PhoneNumber: ResolverTypeWrapper<PhoneNumber>;
  PhoneNumberInput: PhoneNumberInput;
  RefreshTokenInput: RefreshTokenInput;
  RootMutationType: ResolverTypeWrapper<{}>;
  RootQueryType: ResolverTypeWrapper<{}>;
  SignupInput: SignupInput;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  TimeInHm: ResolverTypeWrapper<Scalars['TimeInHm']['output']>;
  Upload: ResolverTypeWrapper<Scalars['Upload']['output']>;
  User: ResolverTypeWrapper<User>;
  ValidationError: ResolverTypeWrapper<ValidationError>;
  VerificationIdType: VerificationIdType;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  Address: Address;
  AddressInput: AddressInput;
  Bank: Bank;
  BankAccount: BankAccount;
  Boolean: Scalars['Boolean']['output'];
  Business: Business;
  BusinessCategory: BusinessCategory;
  BusinessOnboarding: BusinessOnboarding;
  BusinessOnboardingBankAccountInput: BusinessOnboardingBankAccountInput;
  BusinessOnboardingInput: BusinessOnboardingInput;
  BusinessOnboardingOwnerInput: BusinessOnboardingOwnerInput;
  BusinessOnboardingSocialMediaInput: BusinessOnboardingSocialMediaInput;
  BusinessOwner: BusinessOwner;
  BusinessSocialMedia: BusinessSocialMedia;
  DateTime: Scalars['DateTime']['output'];
  Error: ResolversInterfaceTypes<ResolversParentTypes>['Error'];
  Float: Scalars['Float']['output'];
  GeoCoordinates: GeoCoordinates;
  GeoCoordinatesInput: GeoCoordinatesInput;
  ID: Scalars['ID']['output'];
  Image: Image;
  Location: Location;
  Login: Login;
  LoginInput: LoginInput;
  LogoutInput: LogoutInput;
  Media: Media;
  PhoneNumber: PhoneNumber;
  PhoneNumberInput: PhoneNumberInput;
  RefreshTokenInput: RefreshTokenInput;
  RootMutationType: {};
  RootQueryType: {};
  SignupInput: SignupInput;
  String: Scalars['String']['output'];
  TimeInHm: Scalars['TimeInHm']['output'];
  Upload: Scalars['Upload']['output'];
  User: User;
  ValidationError: ValidationError;
}>;

export type AuthenticatedDirectiveArgs = { };

export type AuthenticatedDirectiveResolver<Result, Parent, ContextType = any, Args = AuthenticatedDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type ClientDirectiveArgs = { };

export type ClientDirectiveResolver<Result, Parent, ContextType = any, Args = ClientDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type AddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['Address'] = ResolversParentTypes['Address']> = ResolversObject<{
  buildingName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['Location'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BankResolvers<ContextType = any, ParentType extends ResolversParentTypes['Bank'] = ResolversParentTypes['Bank']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BankAccountResolvers<ContextType = any, ParentType extends ResolversParentTypes['BankAccount'] = ResolversParentTypes['BankAccount']> = ResolversObject<{
  accountHolder?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  accountNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bank?: Resolver<ResolversTypes['Bank'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BusinessResolvers<ContextType = any, ParentType extends ResolversParentTypes['Business'] = ResolversParentTypes['Business']> = ResolversObject<{
  address?: Resolver<ResolversTypes['Address'], ParentType, ContextType>;
  bankAccount?: Resolver<ResolversTypes['BankAccount'], ParentType, ContextType>;
  category?: Resolver<ResolversTypes['BusinessCategory'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  members?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  owner?: Resolver<ResolversTypes['BusinessOwner'], ParentType, ContextType>;
  phoneNumber?: Resolver<ResolversTypes['PhoneNumber'], ParentType, ContextType>;
  socialMedia?: Resolver<ResolversTypes['BusinessSocialMedia'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BusinessCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['BusinessCategory'] = ResolversParentTypes['BusinessCategory']> = ResolversObject<{
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BusinessOnboardingResolvers<ContextType = any, ParentType extends ResolversParentTypes['BusinessOnboarding'] = ResolversParentTypes['BusinessOnboarding']> = ResolversObject<{
  address?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  bankAccountBank?: Resolver<Maybe<ResolversTypes['Bank']>, ParentType, ContextType>;
  bankAccountHolder?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bankAccountNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  category?: Resolver<Maybe<ResolversTypes['BusinessCategory']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerAddress?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  ownerFirstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerIdPhotos?: Resolver<Maybe<Array<ResolversTypes['Media']>>, ParentType, ContextType>;
  ownerIdType?: Resolver<Maybe<ResolversTypes['VerificationIdType']>, ParentType, ContextType>;
  ownerLastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['PhoneNumber']>, ParentType, ContextType>;
  socialMedia?: Resolver<Maybe<ResolversTypes['BusinessSocialMedia']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BusinessOwnerResolvers<ContextType = any, ParentType extends ResolversParentTypes['BusinessOwner'] = ResolversParentTypes['BusinessOwner']> = ResolversObject<{
  address?: Resolver<ResolversTypes['Address'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  idPhotos?: Resolver<Array<ResolversTypes['Media']>, ParentType, ContextType>;
  idType?: Resolver<ResolversTypes['VerificationIdType'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BusinessSocialMediaResolvers<ContextType = any, ParentType extends ResolversParentTypes['BusinessSocialMedia'] = ResolversParentTypes['BusinessSocialMedia']> = ResolversObject<{
  facebook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  instagram?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  snapchat?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  website?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type ErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['Error'] = ResolversParentTypes['Error']> = ResolversObject<{
  __resolveType: TypeResolveFn<'ValidationError', ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ErrorTypes'], ParentType, ContextType>;
}>;

export type GeoCoordinatesResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeoCoordinates'] = ResolversParentTypes['GeoCoordinates']> = ResolversObject<{
  lat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  long?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ImageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Image'] = ResolversParentTypes['Image']> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  large?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  medium?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  small?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Location'] = ResolversParentTypes['Location']> = ResolversObject<{
  coordinates?: Resolver<ResolversTypes['GeoCoordinates'], ParentType, ContextType>;
  formattedAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LoginResolvers<ContextType = any, ParentType extends ResolversParentTypes['Login'] = ResolversParentTypes['Login']> = ResolversObject<{
  accessToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  refreshToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MediaResolvers<ContextType = any, ParentType extends ResolversParentTypes['Media'] = ResolversParentTypes['Media']> = ResolversObject<{
  contentType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PhoneNumberResolvers<ContextType = any, ParentType extends ResolversParentTypes['PhoneNumber'] = ResolversParentTypes['PhoneNumber']> = ResolversObject<{
  countryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  number?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RootMutationTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RootMutationType'] = ResolversParentTypes['RootMutationType']> = ResolversObject<{
  login?: Resolver<Maybe<ResolversTypes['Login']>, ParentType, ContextType, Partial<RootMutationTypeLoginArgs>>;
  logout?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<RootMutationTypeLogoutArgs>>;
  refreshToken?: Resolver<Maybe<ResolversTypes['Login']>, ParentType, ContextType, Partial<RootMutationTypeRefreshTokenArgs>>;
  signup?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, Partial<RootMutationTypeSignupArgs>>;
  submitBusinessOnboarding?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updateBusinessOnboarding?: Resolver<ResolversTypes['BusinessOnboarding'], ParentType, ContextType, RequireFields<RootMutationTypeUpdateBusinessOnboardingArgs, 'input'>>;
  uploadMedia?: Resolver<ResolversTypes['Media'], ParentType, ContextType, RequireFields<RootMutationTypeUploadMediaArgs, 'file'>>;
}>;

export type RootQueryTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RootQueryType'] = ResolversParentTypes['RootQueryType']> = ResolversObject<{
  banks?: Resolver<Array<ResolversTypes['Bank']>, ParentType, ContextType>;
  business?: Resolver<Maybe<ResolversTypes['Business']>, ParentType, ContextType>;
  businessCategories?: Resolver<Array<ResolversTypes['BusinessCategory']>, ParentType, ContextType>;
  businessOnboarding?: Resolver<Maybe<ResolversTypes['BusinessOnboarding']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['ValidationError']>, ParentType, ContextType>;
  me?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
}>;

export interface TimeInHmScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['TimeInHm'], any> {
  name: 'TimeInHm';
}

export interface UploadScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Upload'], any> {
  name: 'Upload';
}

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['PhoneNumber']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ValidationErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ValidationError'] = ResolversParentTypes['ValidationError']> = ResolversObject<{
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ErrorTypes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = any> = ResolversObject<{
  Address?: AddressResolvers<ContextType>;
  Bank?: BankResolvers<ContextType>;
  BankAccount?: BankAccountResolvers<ContextType>;
  Business?: BusinessResolvers<ContextType>;
  BusinessCategory?: BusinessCategoryResolvers<ContextType>;
  BusinessOnboarding?: BusinessOnboardingResolvers<ContextType>;
  BusinessOwner?: BusinessOwnerResolvers<ContextType>;
  BusinessSocialMedia?: BusinessSocialMediaResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  Error?: ErrorResolvers<ContextType>;
  GeoCoordinates?: GeoCoordinatesResolvers<ContextType>;
  Image?: ImageResolvers<ContextType>;
  Location?: LocationResolvers<ContextType>;
  Login?: LoginResolvers<ContextType>;
  Media?: MediaResolvers<ContextType>;
  PhoneNumber?: PhoneNumberResolvers<ContextType>;
  RootMutationType?: RootMutationTypeResolvers<ContextType>;
  RootQueryType?: RootQueryTypeResolvers<ContextType>;
  TimeInHm?: GraphQLScalarType;
  Upload?: GraphQLScalarType;
  User?: UserResolvers<ContextType>;
  ValidationError?: ValidationErrorResolvers<ContextType>;
}>;

export type DirectiveResolvers<ContextType = any> = ResolversObject<{
  authenticated?: AuthenticatedDirectiveResolver<any, any, ContextType>;
  client?: ClientDirectiveResolver<any, any, ContextType>;
}>;
