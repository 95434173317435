import {Toaster, useMediaQuery} from '@cashiaApp/web-components';
import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import RequireAuthLayout from './AuthRequired';
import Validation from '../pages/auth/EmailValidation';
import AuthComponent from '../pages/auth/Signup';
import HomePage from '../pages/home';
import ActivateAccount from '../pages/home/ActivateAccount';
import LinksPage from '../pages/links';
import BusinessInfo from '../pages/selfOnboarding/components/BusinessInfo';
import BusinessOwnerInfo from '../pages/selfOnboarding/components/BusinessOwnerInfo';
import Success from '../pages/selfOnboarding/components/Success';
import BankInfo from '../pages/selfOnboarding/components/bankinfo';
import SocialMedia from '../pages/selfOnboarding/components/socialMedia';
import SelfOnboardingLayout from '../pages/selfOnboarding/layout';
import Terms from '../pages/terms/terms';

const AppRoutes = () => {
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  return (
    <>
      <Toaster
        richColors
        toastOptions={{}}
        position={isDesktop ? 'bottom-left' : 'top-right'}
      />
      <Routes>
        <Route path="/terms" element={<Terms />} />
        <Route index element={<Navigate to="/login" replace />} />
        <Route path="/login" element={<AuthComponent />} />
        <Route path="/signup" element={<AuthComponent />} />
        <Route path="/validation" element={<Validation />} />
        <Route path="/" element={<RequireAuthLayout />}>
          <Route path="home" element={<HomePage />}>
            <Route path="success" element={<Success />} />
            <Route index path="" element={<ActivateAccount />} />
            <Route path="self-onboarding" element={<SelfOnboardingLayout />}>
              <Route index path="business-info" element={<BusinessInfo />} />
              <Route path="bank-info" element={<BankInfo />} />
              <Route path="social-media" element={<SocialMedia />} />
              <Route
                index
                path="business-owner-info"
                element={<BusinessOwnerInfo />}
              />
            </Route>
          </Route>
          <Route path="links" element={<LinksPage />} />
        </Route>
      </Routes>
    </>
  );
};
export default AppRoutes;
