import {Resolvers} from '@apollo/client';
import {nanoid} from 'nanoid/non-secure';

import {
  RootMutationTypeResolvers,
  RootQueryTypeResolvers,
  User,
} from '../../../graphql/generated';

export const mockUser: User = {
  __typename: 'User',
  id: nanoid(),
  firstName: 'Melissa',
  lastName: 'Mukami',
  email: 'melissamukami@gmail.com',
  phone: {
    __typename: 'PhoneNumber',
    countryCode: '+254',
    number: '123456789',
  },
  createdAt: new Date().toISOString(),
  logo: {
    __typename: 'Image',
    id: nanoid(),
    small:
      'https://image.shutterstock.com/image-vector/vintage-mexican-food-poster-design-600w-1049319014.jpg',
    medium:
      'https://image.shutterstock.com/image-vector/vintage-mexican-food-poster-design-600w-1049319014.jpg',
    large:
      'https://image.shutterstock.com/image-vector/vintage-mexican-food-poster-design-600w-1049319014.jpg',
  },
};

const queryResolvers: RootQueryTypeResolvers = {
  me: () => {
    return mockUser;
  },
};

const mutationResolvers: RootMutationTypeResolvers = {};

const resolvers: Resolvers = {
  Query: {...queryResolvers},
  Mutation: {...mutationResolvers},
};

export default resolvers;
