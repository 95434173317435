import {toast} from '@cashiaApp/web-components';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';

import {ReactComponent as AvatarIcon} from '../../../assets/icons/AvatarIcon.svg';
import {ReactComponent as AvatarIconActive} from '../../../assets/icons/avatar-icon-red.svg';
import {ReactComponent as BackIcon} from '../../../assets/icons/back-icon-red.svg';
import {ReactComponent as BankIconActive} from '../../../assets/icons/bank-icon-red.svg';
import {ReactComponent as BankIcon} from '../../../assets/icons/bank-icon.svg';
import {ReactComponent as BusinessIconActive} from '../../../assets/icons/business-icon-red.svg';
import {ReactComponent as CheckIcon} from '../../../assets/icons/check.svg';
import {ReactComponent as SocialIconActive} from '../../../assets/icons/social-media-red.svg';
import {ReactComponent as SocialIcon} from '../../../assets/icons/social-media.svg';
import {
  BusinessOnboardingBankAccountInput,
  BusinessOnboardingOwnerInput,
  BusinessOnboardingSocialMediaInput,
  Media,
  useGetBusinessOnboardingQuery,
  User,
  useSubmitBusinessOnboardingMutation,
  useUpdateBusinessOnboardingMutation,
} from '../../../graphql/generated';
import {useUserAuth} from '../../../utils/user';
import {BussinessInfoForm} from '../components/BusinessInfo';

interface OnboardingDataInput {
  buisnessInfo?: BussinessInfoForm;
  ownerInfo?: BusinessOnboardingOwnerInput & {idFile?: Media | File};
  bankInfo?: BusinessOnboardingBankAccountInput;
  socialMedia?: BusinessOnboardingSocialMediaInput;
}

type SelfOnboardingContent = {
  step?: number;
  totalSteps: number;
  onboardingData?: OnboardingDataInput;
  setOnboardingData?: React.Dispatch<
    React.SetStateAction<OnboardingDataInput | undefined>
  >;
  goBack?: () => void;
  update?: (
    input?: OnboardingDataInput,
    completeOnboarding?: boolean,
    callBackFn?: () => void
  ) => void;
  user?: User;
  updateLoading: boolean;
};

enum Steps {
  BussinessInfo = 'Business Information',
  BussinessOwnerInfo = 'Business Owner Info',
  BankAccount = 'Bank Account Details',
  SocialMedia = 'Social Media',
}

export const links = [
  {
    title: Steps.BussinessInfo,
    description:
      'We require your business information to verify the identity of your business and its key stakeholders.',
    link: `/home/self-onboarding/business-info`,
  },
  {
    title: Steps.BussinessOwnerInfo,
    description:
      'We require your business information to verify the identity of your business and its key stakeholders. Please provide us with more details about the key stakeholder who is authorised to create this business profile.',
    link: `/home/self-onboarding/business-owner-info`,
  },
  {
    title: Steps.BankAccount,
    description:
      'Cashia collects your bank account details for payouts of your funds. Please provide us with the bank account you would like your payouts to be made to.',
    link: `/home/self-onboarding/bank-info`,
  },
  {
    title: Steps.SocialMedia,
    description:
      'Please provide us with your social media or website information so that we may verify your business.',
    link: `/home/self-onboarding/social-media`,
  },
];

const SelfOnboardingContentContext = createContext<SelfOnboardingContent>({
  step: 0,
  totalSteps: links.length,
  updateLoading: false,
});

export const useSelfOnboardingContentContext = () =>
  useContext(SelfOnboardingContentContext);

const SelfOnboardingLayout = () => {
  const {user} = useUserAuth();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const {pathname} = useLocation();
  const isActive = useCallback((path: string) => pathname === path, [pathname]);
  const [onboardingData, setOnboardingData] = useState<OnboardingDataInput>();

  const {data, refetch} = useGetBusinessOnboardingQuery();
  const businessOnboardingData = useMemo(
    () => data?.businessOnboarding,
    [data]
  );

  const [submitBusinessOnboardingMutation, {error}] =
    useSubmitBusinessOnboardingMutation();

  useEffect(() => {
    error?.message && toast.error(error?.message || '');
  }, [error]);

  useEffect(() => {
    if (!businessOnboardingData) return;
    setOnboardingData(() => ({
      buisnessInfo: {
        businessName: businessOnboardingData.name || '',
        category: businessOnboardingData.category?.id || '',
        description: businessOnboardingData.description || '',
        phoneNumber: {
          countryCode: businessOnboardingData.phoneNumber?.countryCode || '',
          number: businessOnboardingData.phoneNumber?.number || '',
        },
        location:
          businessOnboardingData.address?.location?.formattedAddress || '',
        building: businessOnboardingData.address?.buildingName || '',
      },
      ownerInfo: {
        address: {
          location:
            businessOnboardingData.ownerAddress?.location.formattedAddress ||
            '',
          coordinates: businessOnboardingData?.ownerAddress?.location
            ?.coordinates || {
            lat: 0,
            long: 0,
          },
          buildingName:
            businessOnboardingData?.ownerAddress?.buildingName || '',
        },
        firstName: businessOnboardingData.ownerFirstName,
        idPhotos: businessOnboardingData.ownerIdPhotos?.map((x) => x.id) || [],
        idFile: businessOnboardingData.ownerIdPhotos?.[0],
        idType: businessOnboardingData.ownerIdType,
        lastName: businessOnboardingData.ownerLastName,
      },
      bankInfo: {
        accountHolder: businessOnboardingData.bankAccountHolder,
        accountNumber: businessOnboardingData.bankAccountNumber,
        bankId: businessOnboardingData.bankAccountBank?.id,
      },
      socialMedia: {...businessOnboardingData.socialMedia},
    }));
  }, [businessOnboardingData]);

  const [updateBusinessOnboardingMutation, {loading: updateLoading}] =
    useUpdateBusinessOnboardingMutation();

  const update = useCallback(
    (
      input?: OnboardingDataInput,
      completeOnboarding?: boolean,
      callBackFn?: () => void
    ) => {
      callBackFn?.();
      links[step]?.title === Steps.BussinessOwnerInfo &&
        delete input?.ownerInfo?.idFile;

      updateBusinessOnboardingMutation({
        variables: {
          input:
            links[step]?.title === Steps.BussinessInfo
              ? {
                  name: input?.buisnessInfo?.businessName,
                  categoryId: input?.buisnessInfo?.category,
                  description: input?.buisnessInfo?.description,
                  phoneNumber: input?.buisnessInfo?.phoneNumber,
                  address: {
                    location: input?.buisnessInfo?.location || '',
                    coordinates: {
                      lat: 0,
                      long: 0,
                    },
                    buildingName: input?.buisnessInfo?.building,
                  },
                }
              : links[step]?.title === Steps.BankAccount
              ? {
                  bankAccount: {
                    ...input?.bankInfo,
                  },
                }
              : links[step]?.title === Steps.BussinessOwnerInfo
              ? {
                  owner: {
                    ...input?.ownerInfo,
                  },
                }
              : links[step]?.title === Steps.SocialMedia
              ? {
                  socialMedia: {
                    ...input?.socialMedia,
                  },
                }
              : {},
        },
      })
        .then((res) => {
          if (res.data?.updateBusinessOnboarding) {
            if (completeOnboarding) {
              submitBusinessOnboardingMutation()
                .then((submitOnboardingRes) => {
                  if (submitOnboardingRes.data?.submitBusinessOnboarding) {
                    navigate('/home/success');
                  }
                })
                .catch((e) => toast.error(`${e}`));
              return;
            }
            void refetch();
            navigate(links[step + 1]?.link || '');
          }
        })
        .catch((e) => {
          toast.error(`${e}`);
        });
    },
    [
      navigate,
      step,
      updateBusinessOnboardingMutation,
      submitBusinessOnboardingMutation,
      refetch,
    ]
  );

  const goBack = useCallback(() => {
    navigate(links[step - 1]?.link || '');
  }, [step, navigate]);

  useEffect(() => {
    setStep(links.map((menu) => menu.link).indexOf(pathname));
  }, [pathname]);

  const icons = useMemo(
    () => ({
      [Steps.BussinessInfo]:
        links[step]?.title === Steps.BussinessInfo ? (
          <BusinessIconActive />
        ) : (
          <CheckIcon />
        ),
      [Steps.BussinessOwnerInfo]:
        links[step]?.title === Steps.BussinessOwnerInfo ? (
          <AvatarIconActive />
        ) : (
          <AvatarIcon />
        ),
      [Steps.BankAccount]:
        links[step]?.title === Steps.BankAccount ? (
          <BankIconActive />
        ) : (
          <BankIcon />
        ),
      [Steps.SocialMedia]:
        links[step]?.title === Steps.SocialMedia ? (
          <SocialIconActive />
        ) : (
          <SocialIcon />
        ),
    }),
    [step]
  );

  return (
    <div className="flex gap-x-16">
      <div className="flex flex-col border rounded-xl w-[45%] px-10 w-[455px] h-[800px] justify-between max-xl:hidden">
        <div>
          <p className="font-semibold leading-[24px] text-lg p-2 mt-4  text-black mb-5 line">
            Let's get you registered.
          </p>
          {links.map((menu, index) => (
            <div
              onClick={() => step > index && navigate(menu.link)}
              className={`flex p-4 rounded-lg border-[1px] border-lightenedGrey flex-col mb-4 cursor-pointer ${
                isActive(menu.link)
                  ? 'bg-lightPink'
                  : step > index
                  ? 'bg-lightGreen'
                  : 'bg-white'
              }`}
              key={menu.title}>
              <div
                className={`px-1 flex flex-row ${
                  !isActive(menu.link) ? 'items-center' : ''
                }`}>
                {step > index ? <CheckIcon /> : icons[menu.title]}
                <div className="flex flex-col pl-3">
                  <p
                    className={`font-semibold flex ${
                      isActive(menu.link)
                        ? 'text-active-red'
                        : step > index
                        ? 'text-darkGreen'
                        : 'text-foggy'
                    }`}>
                    {menu.title}
                  </p>
                  {isActive(menu.link) && (
                    <p className="text-active-red text-[14px] w-[282px] font-[500] mt-1">
                      {menu.title === Steps.BussinessOwnerInfo ? (
                        <>
                          {' '}
                          <span>
                            {menu.description.split('. ')[0]}.
                          </span> <br /> <br />
                          <span>{menu.description.split('. ')[1]}</span>{' '}
                        </>
                      ) : (
                        menu.description
                      )}
                    </p>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div
          className="w-[300px] flex flex-row items-center mb-5 cursor-pointer"
          onClick={() => navigate('/home')}>
          <BackIcon />
          <p className="font-[600] text-[14px] text-active-red pl-2 pt-1">
            Return to home
          </p>
        </div>
      </div>
      <div className="flex flex-col rounded-lg max-md:px-2 px-5 h-[85vh] max-md:h-[90vh] w-full">
        <SelfOnboardingContentContext.Provider
          value={{
            update,
            goBack,
            user,
            onboardingData,
            setOnboardingData,
            totalSteps: links.length,
            updateLoading,
          }}>
          <Outlet />
        </SelfOnboardingContentContext.Provider>
      </div>
    </div>
  );
};

export default SelfOnboardingLayout;
